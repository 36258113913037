@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap');

html {
  background-color: #f5f5f5;
  font-family: 'DM Serif Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}
